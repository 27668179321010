// ================================================================================
// ================================ PUBLIC STYLES  ================================
// ================================================================================

// The Total Size (not Gziped) of all of this CSS is: 300 KB.
// The Size of each import and group is indicated beside each line.
// This is up to date as of Dec 3, 2019.

// ------------------------- Import Google fonts ----------------------
// // mulish-regular - latin
@font-face {
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('/fonts/mulish-v13-latin-regular.woff2') format('woff2'); // Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+
}
// mulish-600 - latin
@font-face {
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url('/fonts/mulish-v13-latin-600.woff2') format('woff2'); // Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+
}
// mulish-700 - latin
@font-face {
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url('/fonts/mulish-v13-latin-700.woff2') format('woff2'); // Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+
}
// frank-ruhl-libre-regular - latin
@font-face {
  font-family: 'Frank Ruhl Libre';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('/fonts/frank-ruhl-libre-v20-latin-regular.woff2') format('woff2'); // Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+
}
// frank-ruhl-libre-500 - latin
@font-face {
  font-family: 'Frank Ruhl Libre';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url('/fonts/frank-ruhl-libre-v20-latin-500.woff2') format('woff2'); // Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+
}
// frank-ruhl-libre-700 - latin
@font-face {
  font-family: 'Frank Ruhl Libre';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url('/fonts/frank-ruhl-libre-v20-latin-700.woff2') format('woff2'); // Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+
}
// manrope-regular
@font-face {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('/fonts/manrope-regular.woff2') format('woff2'); // Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+
}
// manrope-medium
@font-face {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url('/fonts/manrope-medium.woff2') format('woff2'); // Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+
}
// manrope-semibold
@font-face {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url('/fonts/manrope-semibold.woff2') format('woff2'); // Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+
}
// manrope-bold
@font-face {
  font-family: 'Manrope Bold';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url('/fonts/manrope-bold.woff2') format('woff2'); // Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+
}

.accessibility-widget-wrapper {
  position: fixed;
  z-index: 10;
  right: 3em;
  bottom: 3em;
}

// Hide the TrustArc Cookie Banner
// sass-lint:disable no-css-comments
// sass-lint:disable class-name-format
// sass-lint:disable no-ids
.truste_cursor_pointer {
  display: none;
}

#truste-consent-track {
  z-index: 9999;
}

// Variables (0 KB)
@import 'variables/base';

// Mixins (0 KB)
@import 'mixins/base';

// Vendor (17.1 KB)
@import '../vendor/reset/reset'; // 0.946 KB
@import '../vendor/reset/box-sizing'; // 0.085 KB
@import '../vendor/reset/img'; // 0.026 KB
@import 'node_modules/chartist/dist/index'; // 11.6 KB
@import '../vendor/chartist/chartist-settings-overides';

// General (9.8 KB)
@import 'application'; // 2.1 KB
@import 'animations'; // 1.9 KB
@import 'base/button'; // 3.2 KB
@import 'base/input'; // 1.3 KB
@import 'base/link'; // 0.138 KB
@import 'base/select'; // 0.039 KB
@import 'base/shared'; // 0.319 KB
@import 'base/typography'; // 0.628 KB
@import 'important'; // 0.237 KB

// Pages
@import 'pages/cms';
@import 'pages/search';
@import 'pages/range-slider';
@import 'pages/swiper';

// Swiper.js
@import 'swiper/css/bundle';
@import 'swiper/css';
@import 'swiper/css/navigation';
@import 'swiper/css/pagination';
