// sass-lint:disable no-important
@import 'src/styles/imports';

$desktop-horizontal-padding: 4em;

%small-text {
  font-size: 11px;
  line-height: 17px;
  margin-bottom: 1.5em;
}

.features {
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: space-around;
}

.feature-flags {
  font-size: 12px;
  margin: 0 1em;
  color: $color-white;
}

.footer {
  display: flex;
  padding: 2em 0;
  background-color: $color-grey-10;
  color: $color-white;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  @include full-width-layout-container($desktop-horizontal-padding);
}

.block {
  @include desktop {
    padding: 0 1.5em;
  }
}

.block1 {
  width: 200px;
}

.block2 {
  width: 180px;
}

.block3 {
  width: 250px;
  margin-bottom: 2em;
}

.block4 {
  width: 530px;

  a {
    color: $color-white;
    text-decoration: underline;

    &:hover {
      color: $color-white;
    }
  }

  .wrap {
    display: flex;
    @media (max-width: $breakpoint-tablet) {
      margin-bottom: 1em;
      align-items: center;

      img {
        width: auto;
        height: 2em;
      }

      p {
        margin-bottom: 0;
      }
    }

    div {
      margin-right: .7em;
    }
  }

  p {
    @extend %small-text;
  }
}

.heading {
  font-size: 20px;
  margin-bottom: 1em;
  color: var(--themes-colors-primary-very-light);
  font-family: $font-family-serif;
}

.terms-and-conditions {
  a {
    display: block;
    margin-bottom: 1em;
    color: $color-white;

    &:hover {
      color: $color-white;
    }
  }
}

.stay-connected {
  font-size: 20px;
  color: $color-white;
  font-family: $font-family-serif;
  letter-spacing: .6px;
}

.app-store-links {
  margin-top: 1em;

  a {
    display: block;
    max-width: 130px;
    margin-bottom: .5em;
  }
}

.bbb-logo {
  width: 20px;
  object-fit: contain;
}

.exp-logo-container {
  display: flex;
  margin-bottom: 1em;
  align-items: center;

  p {
    font-size: 16px;
    margin-right: 1em;
    margin-bottom: 0;
    color: $color-grey-4;
    font-weight: 600;
  }

  .exp-logo {
    width: 65px;
  }
}

.exp-copyright {
  @extend %small-text;
  margin-bottom: 2em !important;
}

.contact {
  margin: 1em 0;
}

.contact-item {
  font-size: 14px;
  margin-right: .5em;

  a {
    display: flex;
    margin-bottom: 1em;
    align-items: center;
  }

  svg {
    max-width: 16px;
    max-height: 16px;
    margin-right: .5em;
  }

  path {
    fill: $color-white;
  }

  p {
    color: $color-white;
  }
}

.contact-item:nth-of-type(1) {
  svg {
    width: 18px;
    height: 18px;
  }
}

.socials {
  display: flex;
  margin: 1.5em 0;

  a {
    margin-right: .7em;

    .invert {
      path {
        fill: $color-white;
      }
    }

    svg {
      width: 26px;
      height: 26px;
    }
  }
}

.office-address {
  font-size: 14px;
  line-height: 1.5em;
  white-space: pre;
}

@media (max-width: calc($max-width-app + 2*$desktop-horizontal-padding)) {
  .block4 {
    width: 100%;
    padding-left: 0;
  }
}

@media (max-width: $breakpoint-tablet) {
  .hide-mobile {
    display: none !important;
  }

  .heading {
    display: none;
  }

  .footer {
    padding-bottom: 7em;
    flex-direction: column;

    > div {
      width: 100%;
    }
  }

  .block {
    text-align: center;
  }

  .block3 {
    margin-bottom: 0;
  }

  .block4 p {
    margin-bottom: 1.5em;
  }

  .socials {
    justify-content: center;

    a {
      margin: 0 1em;

      svg {
        width: 40px;
        height: 40px;
      }
    }
  }

  .exp-logo-container {
    justify-content: center;
  }

  .app-store-links {
    display: flex;
    justify-content: center;

    a { margin: .5em; }
  }

  .contact {
    display: flex;
    margin-bottom: 0;
    flex-wrap: wrap;
    justify-content: center;
    .contact-item { margin: 0 1em; }
  }

  .contact-item,
  .office-address {
    font-size: 14px;
    line-height: 17px;
    margin-bottom: 1.5em;
  }

  .terms-and-conditions {
    > div {
      display: flex;
      align-items: center;
      justify-content: center;

      a {
        margin: 1em;
      }
    }
  }
}

@media (min-width: $breakpoint-tablet) {
  .hide-desktop {
    display: none !important;
  }

  .block1 { padding-left: 0; }
  .block4 { padding-right: 0; }
}

.listing-links {
  a {
    display: block;
    margin-top: 1em;
    margin-bottom: 1em;
    color: $color-white;

    &:hover {
      color: $color-white;
    }
  }
}